import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Grid, Box, Paper, TextField, Button,
    Snackbar, Alert, CircularProgress, MenuItem, Tooltip
} from '@mui/material';
import { Search, Send } from '@mui/icons-material';
import SidebarNavigation from '../utils/SideBarNavigation';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import { getDatabase, ref, push, onValue } from 'firebase/database';
import TitleSection from '../utils/TittleTheme';

const ESICPage = () => {
    const [formData, setFormData] = useState({ name: '', email: '', requestType: '', description: '' });
    const [filter, setFilter] = useState('');
    const [ticketNumber, setTicketNumber] = useState(null);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchResult, setSearchResult] = useState({ found: false, message: '' });
    const [submissionLimitReached, setSubmissionLimitReached] = useState(false);

    const databaseInstance = getDatabase(firebaseApp);
    const esicRef = ref(databaseInstance, `${KEY_REF}/esic/`);

    const requestTypes = [
        "Solicitação de Documentos", "Informações sobre Licitações", "Consulta de Tributos", "Serviços de Saúde",
        "Programas Sociais", "Regulamentações de Zoneamento", "Solicitações de Infraestrutura", "Consulta de Processos",
        "Informações sobre Eventos", "Solicitações de Transporte", "Educação e Creches", "Segurança e Vigilância",
        "Políticas Ambientais", "Programas Culturais", "Requisição de Serviços Públicos", "Acesso à Informação Pública",
        "Transparência Fiscal", "Consulta de Orçamentos e Despesas", "Relatórios de Gestão", "Auditoria de Contas Públicas",
        "Denúncias de Corrupção", "Requisição de Acesso a Contratos", "Solicitação de Licenças Ambientais",
        "Solicitação de Alvarás", "Cadastro e Regularização Imobiliária", "Consulta de Aprovação de Projetos",
        "Relatórios de Segurança Urbana", "Pedidos de Manutenção de Vias", "Solicitação de Coleta de Lixo",
        "Fiscalização de Comércio", "Solicitação de Iluminação Pública", "Solicitações de Equipamentos Urbanos",
        "Regularização de Comércio Ambulante", "Informações sobre Patrimônio Cultural", "Consulta de Dados Estatísticos",
        "Relatórios de Impacto Ambiental", "Consulta de Política Habitacional", "Registro de Reclamações"
    ];

    const generateTicketNumber = () => Math.floor(1000000000 + Math.random() * 9000000000);

    useEffect(() => {
        checkSubmissionLimit();
    }, []);

    const checkSubmissionLimit = () => {
        const submissionData = JSON.parse(localStorage.getItem('submissionData')) || { count: 0, lastSubmission: null };
        const now = new Date();
        const lastSubmissionDate = new Date(submissionData.lastSubmission);

        if (submissionData.count >= 3 && now - lastSubmissionDate < 24 * 60 * 60 * 1000) {
            setSubmissionLimitReached(true);
        } else if (now - lastSubmissionDate >= 24 * 60 * 60 * 1000) {
            localStorage.setItem('submissionData', JSON.stringify({ count: 0, lastSubmission: now }));
            setSubmissionLimitReached(false);
        }
    };

    const incrementSubmissionCount = () => {
        const submissionData = JSON.parse(localStorage.getItem('submissionData')) || { count: 0, lastSubmission: null };
        submissionData.count += 1;
        submissionData.lastSubmission = new Date();
        localStorage.setItem('submissionData', JSON.stringify(submissionData));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    const validateForm = () => {
        return (
            formData.name.trim() &&
            formData.email.trim() &&
            formData.requestType &&
            formData.description.trim().length >= 10
        );
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            setSearchResult({ found: false, message: "Preencha todos os campos corretamente." });
            return;
        }

        if (submissionLimitReached) {
            return;
        }

        const newTicket = generateTicketNumber();
        setLoading(true);
        await push(esicRef, {
            ...formData,
            date: new Date().toLocaleDateString(),
            ticket: newTicket,
            status: "Criado"
        });
        setTicketNumber(newTicket);
        incrementSubmissionCount();
        setFormData({ name: '', email: '', requestType: '', description: '' });
        setSuccess(true);
        setLoading(false);
        checkSubmissionLimit(); // Atualiza o estado para refletir o limite após o envio
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFilterSearch = () => {
        setSearching(true);
        setSelectedRequest(null);

        onValue(ref(databaseInstance, `${KEY_REF}/esic/`), (snapshot) => {
            let foundRequest = null;
            snapshot.forEach((childSnapshot) => {
                const data = childSnapshot.val();
                if (data.ticket?.toString() === filter) {
                    foundRequest = { id: childSnapshot.key, ...data };
                }
            });
            setSearching(false);
            setSelectedRequest(foundRequest);

            if (foundRequest) {
                setSearchResult({ found: true, message: "Ticket encontrado!" });
            } else {
                setSearchResult({ found: false, message: "Ticket não encontrado." });
            }
        });
    };

    return (
        <Container maxWidth="lg" sx={{ py: 5, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
            <Box sx={{ flex: 4 }}>
                <TitleSection title="E-SIC Municipal" subtitle="Solicite informações públicas diretamente à administração" />

                <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>Envie sua solicitação</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Nome" name="name" value={formData.name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                select
                                label="Tipo de Solicitação"
                                name="requestType"
                                value={formData.requestType}
                                onChange={handleChange}
                            >
                                {requestTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Descrição do Pedido (mínimo 10 caracteres)"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip
                                title={submissionLimitReached ? "Você atingiu o limite diário de 3 solicitações." : ""}
                                placement="top"
                                arrow
                            >
                                <span>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={loading || submissionLimitReached}
                                    >
                                        {loading ? <CircularProgress size={24} /> : <Send />}
                                        Enviar
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>

                {ticketNumber && (
                    <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                        <Typography variant="body1" color="success.main">
                            Sua solicitação foi enviada com sucesso!
                        </Typography>
                        <Typography variant="body2">
                            Número do Ticket: <strong>{ticketNumber}</strong>
                        </Typography>
                        <Typography variant="body2">Status: Criado</Typography>
                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                            <strong>Importante:</strong> Salve este número para futuras consultas.
                        </Typography>
                    </Paper>
                )}

                <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Pesquisar solicitações por número de ticket"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value.toLowerCase())}
                        sx={{ mb: 1 }}
                    />
                    <Button variant="contained" color="primary" onClick={handleFilterSearch} startIcon={<Search />} disabled={searching}>
                        {searching ? <CircularProgress size={24} /> : "Pesquisar"}
                    </Button>
                    <Typography variant="caption" color="textSecondary" sx={{ mt: 2, display: 'block' }}>
                        O número do ticket foi informado no momento da criação da sua solicitação. Por favor, utilize-o para pesquisar.
                    </Typography>
                </Paper>

                {selectedRequest && (
                    <Paper elevation={4} sx={{ p: 4, mt: 3, border: '2px solid #1976d2', borderRadius: 2 }}>
                        <Typography variant="h5" sx={{ color: '#1976d2', mb: 2 }}>
                            Detalhes da Solicitação
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Ticket: #{selectedRequest.ticket}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Tipo: {selectedRequest.requestType}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Data: {selectedRequest.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Descrição: {selectedRequest.description}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Status: {selectedRequest.status}
                        </Typography>
                        {selectedRequest.retorno && selectedRequest.retorno.trim() && (
                            <Typography
                                
                                sx={{
                                    mt: 2,
                                    p: 2,
                                    backgroundColor: '#e3f2fd',
                                    color: '#0d47a1',
                                    border: '1px solid #0d47a1',
                                    borderRadius: 1,
                                    textAlign: 'center',
                                    fontSize: 21
                                }}
                            >
                                Retorno: {selectedRequest.retorno}
                            </Typography>
                        )}
                    </Paper>
                )}

            </Box>

            <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }}>
                <SidebarNavigation />
            </Box>

            <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Solicitação enviada com sucesso! Número do Ticket: {ticketNumber}. Guarde este número para consultas futuras.
                </Alert>
            </Snackbar>

            <Snackbar open={searchResult.message !== ''} autoHideDuration={4000} onClose={() => setSearchResult({ ...searchResult, message: '' })}>
                <Alert onClose={() => setSearchResult({ ...searchResult, message: '' })} severity={searchResult.found ? "success" : "error"} sx={{ width: '100%' }}>
                    {searchResult.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ESICPage;
